<template>
  <div>
    <!--  -->
    <v-data-table
        :footer-props="{ itemsPerPageOptions, itemsPerPageText }"
        :header-props="{ sortByText: `Trier par` }"
        :headers="headers"
        :items="users"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :server-items-length="totalUsers"
        :loading="loading"
        class="elevation-1 ma-2 pa-1"
        loading-text="Chargement des éléments"
        mobile-breakpoint="850"
    >
      <!-- Tableau  ------------------------------------------------------------------------------------>
      <template v-slot:top>
        <v-toolbar-title
            class="mx-4"
            v-if="!$vuetify.breakpoint.smAndUp"
        >
          Utilisateurs
        </v-toolbar-title>
        <v-text-field
            v-model="search"
            @input="triggerSearch"
            append-icon="mdi-magnify"
            label="Recherche"
            single-line
            hide-details
            class="mx-4"
            v-if="!$vuetify.breakpoint.smAndUp"
        />
        <v-toolbar flat>
          <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Utilisateurs</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
              v-if="$vuetify.breakpoint.smAndUp"
          ></v-divider>

          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherche"
              single-line
              hide-details
              class="mx-4"
              v-if="$vuetify.breakpoint.smAndUp"
          ></v-text-field>
          <v-spacer></v-spacer>
          <NextButton
                  width="150"
                  color="primary"
                  id="suppression"
                  class="pl-3 pr-1"
                  text="Suppression"
                  icon="mdi-delete-outline"
                  :disable="user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
                  @clicked="suppressionArchivageCompte"
          />
          <NextButton
              width="240"
              color="primary"
              id="nettoyer-les-comptes"
              class="pl-3 pr-1 ml-4"
              text="Nettoyage des comptes"
              icon="mdi-broom"
              :disable="user.role !== `GESTIONNAIRE_ARBS`"
              @clicked="cleanAccounts"
          />
          <NextButton
              width="270"
              color="primary"
              id="affecter-des-ouvrages"
              class="pl-3 pr-1 ml-4"
              text="Désaffecter des ouvrages"
              icon="mdi-book-arrow-right-outline"
              :disable="user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
              @clicked="desaffecterOuvrages"
          />
          <NextButton
                width="230"
                color="primary"
                id="affecter-des-ouvrages"
                class="pl-3 pr-1 ml-4"
                text="Affecter des ouvrages"
                icon="mdi-book-arrow-right-outline"
                :disable="user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
                @clicked="affecterOuvrages"
            />
          <!-- Modal New User  --------------------------------------------------------------------------->
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  name="Ajouter un utilisateur"
                  height="44px"
                  color="primary"
                  dark
                  class="AccessButton pl-3 ml-4 pr-1"
                  v-bind="attrs"
                  v-on="on"
                  tile
                  elevation="0"
                  fab
                  width="120"
                  v-if="user.role !== `GESTIONNAIRE_ETABLISSEMENT` && user.role !== `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
                  :disabled="user.role === `GESTIONNAIRE_ETABLISSEMENT` || user.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
                  :outlined="Ishovered"
                  @mouseover="hovered()"
                  @mouseleave="notHovered()"
              >
                Ajouter
                <v-spacer/>
                <v-icon
                    class="ml-5"
                    :class="boutonMove"
                >mdi-plus-circle-outline
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-form
                  ref="form"
                  v-model="valid"
              >
                <v-card-title>
                  <h1 class="text-h4 primary--text">Nouvel Utilisateur</h1>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <!-- ligne1 role-->
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            v-model="editedItem.role"
                            :rules="[
                              () => !!editedItem.role || 'Ce champ est requis',
                            ]"
                            :items="role"
                            label="Role *"
                            placeholder="Select..."
                            required
                            @change="selectRole"
                            no-data-text="Pas de données disponibles"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <!-- ligne2 nom prenom id-->
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedItem.nom"
                            label="Nom *"
                            :rules="[
                              () => !!editedItem.nom || 'Ce champ est requis',
                              () => editedItem.nom.length < 35 || 'nombre de caractère maximum atteint',
                            ]"
                            required
                            v-if="editedItem.role"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedItem.prenom"
                            label="Prénom *"
                            required
                            :rules="[
                              () => !!editedItem.prenom || 'Ce champ est requis',
                              () => editedItem.prenom.length < 35 || 'nombre de caractère maximum atteint',
                            ]"
                            v-if="editedItem.role"
                        ></v-text-field>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="4"
                      >
                        <v-text-field
                            v-model="editedItem.identifiant"
                            label="Identifiant *"
                            required
                            :rules="[
                            () =>
                              !!editedItem.identifiant || 'Ce champ est requis',
                               (v) => {
                                 if(editedItem.role !== `GESTIONNAIRE_ETABLISSEMENT` && editedItem.role !== `GESTIONNAIRE_ETABLISSEMENT_AVANCE` && editedItem.role !== `GESTIONNAIRE_ARBS` && editedItem.role !== `PROF` && !/^[a-z0-9]{8}$/g.test(v)){
                                   return `L'identifiant doit contenir 8 caractères alphanumérique en minuscule.`
                                 } else {
                                   return true
                                 }
                               }
                            ]"
                            v-if="editedItem.role"
                        ></v-text-field>
                      </v-col>
                      <!-- ligne3 mail-->
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-text-field
                            v-model="editedItem.emailContact"
                            :rules="[
                            () =>
                              !!editedItem.emailContact ||
                              'Ce champ est requis',
                            ]"
                            label="Email parental *"
                            v-if="editedItem.role === `ELEVE`"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="editedItem.emailContact"
                            :rules="[
                              () =>
                                !!editedItem.emailContact ||
                                'Ce champ est requis',
                            ]"
                            label="Email *"
                            v-if="
                            editedItem.role === `PROF` ||
                            editedItem.role === `GESTIONNAIRE_ARBS` ||
                            editedItem.role === `GESTIONNAIRE_ETABLISSEMENT` ||
                            editedItem.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
                            required
                        ></v-text-field>
                      </v-col>
                      <!-- ligne4 etablissement-->
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-autocomplete
                            v-model="selectedEtablissement"
                            :items="etablissements"
                            :multiple="editedItem.role === `GESTIONNAIRE_ETABLISSEMENT`|| editedItem.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`"
                            attach
                            :hint="editedItem.infoEtab"
                            persistent-hint
                            label="Etablissement *"
                            v-if="
                            editedItem.role === `PROF` ||
                            editedItem.role === `ELEVE` ||
                            editedItem.role === `GESTIONNAIRE_ETABLISSEMENT`||
                            editedItem.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`
                          "
                            class="pa-1"
                            :menu-props="{ top: true, offsetY: true }"
                            :rules="[
                              () =>
                                !!editedItem.etablissements ||
                                'Ce champ est requis',
                            ]"
                            @change="selectEtab"
                            required
                        ></v-autocomplete>
                      </v-col>
                      <!-- ligne5 classe-->
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <v-select
                            v-model="editedItem.classes"
                            :items="classes"
                            attach
                            :loading="!classesArrayLoading && !!editedItem.etablissements"
                            label="Classe *"
                            v-if="
                              editedItem.role === `ELEVE` &&
                              editedItem.etablissements
                            "
                            :disabled="!editedItem.etablissements && !classesArrayLoading"
                            class="pa-1"
                            :menu-props="{ top: true, offsetY: true }"
                            :rules="[
                              () =>
                                !!editedItem.classes.length ||
                                'Ce champ est requis',
                            ]"
                            required
                        ></v-select>
                        <v-select
                            v-model="editedItem.classes"
                            :items="classes"
                            attach
                            chips
                            :loading="!classesArrayLoading && !!editedItem.etablissements"
                            label="Classes *"
                            dense
                            multiple
                            v-if="
                            editedItem.role === `PROF` &&
                            editedItem.etablissements"
                            :disabled="!editedItem.etablissements && !classesArrayLoading"
                            class="pa-1"
                            :menu-props="{ top: true, offsetY: true }"
                            :rules="[
                              () =>
                                !!editedItem.classes.length ||
                                'Ce champ est requis',
                            ]"
                            required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <!-- ligne6 switch-->
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <Tooltip textToolTip="Désactiver l'accès à cet utilisateur sans supprimer son compte.">
                          <v-switch
                              v-model="editedItem.actif"
                              inset
                              label="Actif"
                              color="green"
                              v-if="
                                editedItem.role === `PROF` ||
                                editedItem.role === `ELEVE`
                              "
                          />
                        </Tooltip>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="6"
                          md="6"
                      >
                        <Tooltip textToolTip="Associer l'accès CAPEEZI à cet élève.">
                          <v-switch
                              v-model="editedItem.soutienBordas"
                              inset
                              label="Accès CAPEEZI"
                              color="green"
                              v-if="editedItem.role === `ELEVE`"
                          />
                        </Tooltip>
                        <pre></pre>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      name="Annuler"
                      color="secondary darken-1"
                      text
                      @click="close"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                      name="Sauvegarder"
                      color="green darken-1"
                      text
                      @click="save"
                      :loading="loading"
                  >
                    Sauvegarder
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <NextButton
                  width="130"
                  color="primary"
                  id="export-utilisateurs"
                  class="pl-3 pr-1 ml-4 hidden-md-and-down"
                  text="Exporter"
                  icon="mdi-download"
                  @clicked="exportUtilisateurs"
              />
        </v-toolbar>
      </template>
      <template v-slot:[`item.nom`]="{ item }">
        <div
            :key="refresh"
            class="d-flex ma-0 pa-0"
            :class="item.nom.length > 10 && $vuetify.breakpoint.width < 1366 ? 'pointeur' : ''"
            v-if="item.nom"
        >
          <span
              v-if="!item.readMoreActivated && $vuetify.breakpoint.width < 1366"
              @click="item.readMoreActivated = true;refresh = !refresh;"
          >{{ item.nom.slice(0, 10) }}
            <span v-if="item.nom.length > 10"><v-icon small> mdi-information-outline </v-icon></span>
          </span>
          <span
              v-if="item.readMoreActivated || $vuetify.breakpoint.width >= 1366"
              @click="
              refresh = !refresh;
                    item.readMoreActivated = false;
                  "
          >
                  {{ item.nom }}
                  <a v-if="
                      item.readMoreActivated &&
                      item.nom.length > 10
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
        </div>
      </template>
      <template v-slot:[`item.identifiant`]="{ item }">
        <div
            :key="refresh"
            class="d-flex ma-0 pa-0"
            :class="item.identifiant.length > 8 && $vuetify.breakpoint.width < 1366 ? 'pointeur' : ''"
            v-if="item.identifiant"
        >
          <span
              v-if="!item.readMoreActivated && $vuetify.breakpoint.width < 1366"
              @click="item.readMoreActivated = true;refresh = !refresh;"
          >{{ item.identifiant.slice(0, 8) }}
            <span v-if="item.identifiant.length > 8"><v-icon small> mdi-information-outline </v-icon></span>
          </span>
          <span
              v-if="item.readMoreActivated || $vuetify.breakpoint.width >= 1366"
              @click="
              refresh = !refresh;
                    item.readMoreActivated = false;
                  "
          >
                  {{ item.identifiant }}
                  <a v-if="
                      item.readMoreActivated &&
                      item.identifiant.length > 8
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
        </div>
      </template>
      <template v-slot:[`item.prenom`]="{ item }">
        <div
            :key="refresh"
            class="d-flex ma-0 pa-0"
            :class="item.prenom.length > 15 && $vuetify.breakpoint.width < 1264? 'pointeur' : ''"
            v-if="item.prenom"
        >
          <span
              v-if="!item.readMoreActivated && $vuetify.breakpoint.width < 1264"
              @click="item.readMoreActivated = true;refresh = !refresh;"
          >{{ item.prenom.slice(0, 15) }}
            <span v-if="item.prenom.length > 15"><v-icon small> mdi-information-outline </v-icon></span>
          </span>
          <span
              v-if="item.readMoreActivated || $vuetify.breakpoint.width >= 1264"
              @click="
              refresh = !refresh;
                    item.readMoreActivated = false;
                  "
          >
                  {{ item.prenom }}
                  <a v-if="
                      item.readMoreActivated &&
                      item.prenom.length > 15
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
        </div>
      </template>
      <template v-slot:[`item.emailContact`]="{ item }">
        <div
            :key="refresh"
            class="d-flex ma-0 pa-0"
            :class="item.emailContact.length > 10 && $vuetify.breakpoint.width < 1264? 'pointeur' : ''"
            v-if="item.emailContact"
        >
          <span
              v-if="!item.readMoreActivated && $vuetify.breakpoint.width < 1264"
              @click="item.readMoreActivated = true;refresh = !refresh;"
          >{{ item.emailContact.slice(0, 10) }}
            <span v-if="item.emailContact.length > 10"><v-icon small> mdi-information-outline </v-icon></span>
          </span>
          <span
              v-if="item.readMoreActivated || $vuetify.breakpoint.width >= 1264"
              @click="
              refresh = !refresh;
                    item.readMoreActivated = false;
                  "
          >
                  {{ item.emailContact }}
                  <a v-if="
                      item.readMoreActivated &&
                      item.emailContact.length > 10
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
        </div>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <div
            :key="refresh"
            class="d-flex ma-0 pa-0"
            :class="item.role.length > 6 && $vuetify.breakpoint.width < 1264 ? 'pointeur' : ''"
            v-if="item.role"
        >
          <span
              v-if="!item.readMoreActivated && $vuetify.breakpoint.width < 1264"
              @click="item.readMoreActivated = true;refresh = !refresh;"
          >{{ item.role.slice(0, 6) }}
            <span v-if="item.role.length > 6"><v-icon small> mdi-information-outline </v-icon></span>
          </span>
          <span
              v-if="item.readMoreActivated || $vuetify.breakpoint.width >= 1264"
              @click="
              refresh = !refresh;
                    item.readMoreActivated = false;
                  "
          >
                  {{ item.role }}
                  <a v-if="
                      item.readMoreActivated &&
                      item.role.length > 6
                    ">
                    <v-icon> mdi-chevron-left </v-icon>
                  </a>
                </span>
        </div>
      </template>
      <!-- colonne classe  ------------------------------------------------------------------------------------>
      <template v-slot:[`item.classes`]="{ item }">
        <div
            class="d-flex"
            v-if="item.classes"
        >
          {{ item.classes.toString() }}
        </div>
      </template>
      <!-- colonne actif  ------------------------------------------------------------------------------------>
      <template v-slot:[`item.actif`]="{ item }">
        <div class="d-flex">
          <v-icon
              class="mr-2"
              :color="getColor(item.actif)"
          >
            {{ actifText(item.actif) }}
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.etablissements`]="{ item }">
        <div
            class="d-flex"
            v-if="item.classes"
        >
          {{ item.etablissements.map(etab => etab.id).toString() }}
        </div>
      </template>
      <!-- Action Button ------------------------------------------------------------------------------->
      <template v-slot:[`item.actions`]="{ item }">
        <v-row :style="{ 'min-width': 160 + 'px' }">
          <Tooltip textToolTip="Modifier l'utilisateur.">
            <v-icon
                class="mr-2"
                color="primary"
                @click="editItem(item);clicked=true;"
                :disabled="clicked"
            >
              mdi-pencil
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Supprimer l'utilisateur.">
            <v-icon
                class="mr-2"
                color="secondary"
                @click="deleteItem(item)"
                :disabled="user.role === `GESTIONNAIRE_ETABLISSEMENT` || item.isEnt"
            >
              mdi-delete
            </v-icon>
          </Tooltip>

          <Tooltip textToolTip="Réinitialiser le mot de passe.">
            <v-icon
                class="mr-2"
                color="purple"
                @click="resetPass(item)"
                :disabled="item.isEnt"
            >
              mdi-lock-reset
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Statistiques de l'utilisateur.">
            <v-icon
                class="mr-2"
                color="green"
                @click="
                $router.push({
                  path: `/admin/user/statistique/${item.identifiant}`,
                }).catch((error) => {
                  if (error.name !== 'NavigationDuplicated') {
                    throw error;
                  }
                });
                clicked=true
              "
                :disabled="item.role === `GESTIONNAIRE_ARBS`||item.role === `GESTIONNAIRE_ETABLISSEMENT`||item.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`||clicked"
            >
              mdi-chart-line
            </v-icon>
          </Tooltip>
          <Tooltip textToolTip="Afficher le QRCode.">
            <v-icon
                class="mr-2"
                color="purple"
                @click="showQRCode(item)"
                :disabled="item.role === `GESTIONNAIRE_ARBS`||item.role === `GESTIONNAIRE_ETABLISSEMENT`||item.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`||clicked || !item.actif || item.isEnt"
            >
              mdi-qrcode-scan
            </v-icon>
          </Tooltip>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-btn
            name="Rafraîchir"
            color="primary"
            @click="initialize"
        >
          <v-icon> mdi-refresh</v-icon>
        </v-btn>
      </template>

    </v-data-table>
    <!-- Modal DELETE ---------------------------------------------------------------------------->
    <ValidationModal
        text="Êtes-vous sûr de vouloir supprimer le compte de cet utilisateur?"
        :status="dialogDelete"
        :loading="loading"
        @validated="deleteItemConfirm()"
        @Canceled="closeDelete()"
    />
    <!-- Modal RESET ----------------------------------------------------------------------------->
    <ValidationModal
        :loading="loading"
        text="Réinitialiser le mot de passe de cet utilisateur ?"
        :status="dialogReset"
        @validated="resetItemConfirm()"
        @Canceled="closeReset()"
    />
    <!-- Modal Redirection Fin de création---------------------------------------------------------------------------->
    <ValidationModal
        text="Souhaitez vous associer des manuels à ce nouvel utilisateur?"
        :status="dialogRedirection"
        @validated="redirectUserConfirm()"
        @Canceled="closeRedirection()"
    />
    <v-dialog
        v-model="dialogQRCode"
        max-width="600px"
    >
      <v-card>
        <v-card-title class="text-h5 text-break justify-center">
          QR Code de l'utilisateur
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-col align="center" class="justify-center">
            <v-skeleton-loader height="300" width="300" type="image" v-if="QRCodeUser === ''"></v-skeleton-loader>
            <v-img class="align-center" v-else :src="`data:image/png;base64, ${QRCodeUser}`" width="300"></v-img>
            <NextButton
                color="primary"
                text="Fermer"
                elevation="0"
                class="mt-4 AccessButton pl-3 pr-1"
                width="130"
                @clicked="dialogQRCode = false"
            >
            </NextButton>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AdminService from "./../../services/AdminService";
import ForgotPWService from "./../../services/ForgotPWService";
import ValidationModal from "../Utility/ValidationModal.vue";
import Tooltip from "../Utility/Tooltip.vue";
import NextButton from "./../Utility/NextButton.vue";

export default {
  components: {
    ValidationModal,
    Tooltip,
    NextButton,
  },
  data: () => ({
    searchTimer: undefined,
    selectedEtablissement: null,
    itemsPerPageOptions: [10, 25, 50, 1000],
    itemsPerPageText: `Lignes par page`,
    Ishovered: false,
    refresh: true,
    boutonMove: "pr-4",
    search: "",
    ERROR_MESSAGE: `Mot de passe invalide :
veuillez utiliser au moins 8 caractères dont une majuscule, une minuscule, un 
chiffre et un caractère spécial.`,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogRedirection: false,
    //
    page: 1,
    etablissement: [],
    totalUsers: 0,
    numberOfPages: 0,
    pageCount: 0,
    size: 10,
    options: {},
    role: ["ELEVE", "GESTIONNAIRE_ARBS", "GESTIONNAIRE_ETABLISSEMENT", "GESTIONNAIRE_ETABLISSEMENT_AVANCE", "PROF"],
    classes: [],
    classesArrayLoading: true,
    etablissements: [],

    dialogQRCode: false,
    QRCodeUser: "",

    headers: [
      {text: "Nom", value: "nom", align: "start"},
      {text: "Prénom", value: "prenom"},
      {text: "Identifiant", value: "identifiant"},
      {text: "Email", value: "emailContact"},
      // Cannot sort due to many to many issue in Spring, await Spring Boot 3 migration
      {text: "ID Etab*", value: "etablissements", sortable: false},
      {text: "Classe", value: "classes"},
      {text: "Rôle", value: "role"},
      {text: "Actif", value: "actif"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    newUserIdentifiant: "",

    users: [],
    editedIndex: -1,
    editedItem: {
      identifiant: "",
      nom: "",
      prenom: "",
      emailContact: "",
      codeAdherent: "",
      role: "",
      actif: true,
      classes: [],
      soutienBordas: false,
      etablissement: null,
      infoEtab: "",
    },
    defaultItem: {
      identifiant: "",
      nom: "",
      prenom: "",
      emailContact: "",
      codeAdherent: "",
      role: "",
      actif: true,
    },
    loading: false,
    valid: false,
    json_fields: {
      identifiant: "utilisateur.identifiant",
      nom: "utilisateur.nom",
      prenom: "utilisateur.prenom",
      emailContact: "utilisateur.emailContact",
      ID_Parent: "utilisateur.codeAdherent",
      role: "utilisateur.role",
      actif: "utilisateur.actif",
      soutienBordas: "utilisateur.soutienBordas",
      classes: "utilisateur.classes",
      dateCreation: "utilisateur.dateCreation",
      dateActivation: "utilisateur.dateActivation",
      dateConnexion: "utilisateur.dateConnexion",
      solde: "utilisateur.solde",
      etablissementID: "utilisateur.etablissements.id",
      etablissementUAI: "utilisateur.etablissements.uai",
      etablissementNom: "utilisateur.etablissements.nom",
      etablissementCp: "utilisateur.etablissements.cp",
      etablissementVille: "utilisateur.etablissements.ville",
      eans: "eans",
    },
    clicked: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  watch: {
    dialog(val) {
      this.etablissementArray();
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogReset(val) {
      val || this.closeReset();
    },
    "editedItem.role": function () {
      this.editedItem.classes = [];
    },
    search: function () {
      if(this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.options.page = 1;
        this.initialize();
      }, 1000)
    },
    options: {
      handler() {
        this.initialize();
      },
    },
    "selectedEtablissement": function () {
      if (this.editedItem.etablissements && this.dialog) {
        this.classes = [];
        this.editedItem.classes = [];
        const id = this.editedItem.etablissements;
        this.classesArray(id, this.editedItem.role);
        AdminService.getEtablissementByID(id).then(
            ({data}) => {
              this.editedItem.infoEtab = `${data.ville} - ${data.nom}`;
            }
        );
      }
    },
    deep: true,
  },

  mounted() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    this.initialize();
  },

  methods: {
    selectEtab(value) {
      if(this.editedItem.role === `GESTIONNAIRE_ETABLISSEMENT` || this.editedItem.role === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`) {
        this.editedItem.etablissements = value
      } else {
        this.editedItem.etablissements = [value]
      }
    },
    selectRole(value) {
      if((this.editedItem !== `GESTIONNAIRE_ETABLISSEMENT` || this.editedItem !== `GESTIONNAIRE_ETABLISSEMENT_AVANCE`) && (value === `GESTIONNAIRE_ETABLISSEMENT` || value === `GESTIONNAIRE_ETABLISSEMENT_AVANCE`) && this.editedItem.etablissements.length > 0) {
        this.selectedEtablissement = this.editedItem.etablissements
      } else if((this.editedItem.role !== `PROF` || this.editedItem.role !== `ELEVE`) && (value === `PROF` || value === 'ELEVE')){
        this.selectedEtablissement = this.editedItem.etablissements[0]
      }
    },
    etablissementArray() {
      if (!this.etablissements.length) {
        AdminService.getEtablissementIds().then(({data}) => {
          data.forEach((etablissement) => {
            this.etablissements.push(`${etablissement.id}`);
          });
        });
      }
    },
    classesArray(id, role) {
      this.classesArrayLoading = false;
      if (role === "ELEVE") {
        AdminService.getClasseByID(id).then(({data}) => {
          data.classes.forEach((classe) => {
            this.classes.push([classe.name]);
          });
          this.classesArrayLoading = true;
        });
      } else {
        AdminService.getClasseByID(id).then(({data}) => {
          data.classes.forEach((classe) => {
            this.classes.push(classe.name);
          });
          this.classesArrayLoading = true;
        });
      }
    },
    hovered() {
      this.Ishovered = true;
      this.boutonMove = "pr-2";
    },
    notHovered() {
      this.Ishovered = false;
      this.boutonMove = "pr-4";
    },
    actifText(item) {
      if (item === true) {
        return "mdi-check-circle-outline";
      } else {
        return "mdi-close-circle-outline";
      }
    },
    getColor(Status) {
      if (Status === true) return "green";
      // else if (calories > 200) return "orange";
      else return "secondary";
    },

    initialize() {
      this.loading = true;
      const {page = 1, itemsPerPage, sortBy, sortDesc} = this.options;
      let pageNumber = page - 1;
      AdminService.getUsers(
          pageNumber,
          itemsPerPage,
          sortBy,
          sortDesc,
          this.search
      )
          .then((element) => {
            this.loading = false;
            this.users = element.data.content;
            this.users.forEach((user) => {
              user.readMoreActivated = false;
            });
            this.totalUsers = element.data.totalElements;
            this.numberOfPages = element.data.totalPages;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue",
            });
            this.loading = false;
          });
    },

    editItem(item) {
      this.$router.push({path: `/admin/users/${item.identifiant}`}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    resetPass(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReset = true;
    },

    showQRCode(item) {
      this.QRCodeUser = '';
      this.dialogQRCode = true;
      AdminService.getQrCode(item.identifiant).then(({data}) => {
        this.QRCodeUser = data;
      });
    },

    deleteItemConfirm() {
      this.loading = true;
      AdminService.deleteUser(this.editedItem)
          .then(() => {
            this.loading = false;
            this.users.splice(this.editedIndex, 1);
            this.closeDelete();
            this.$store.dispatch("setSnackbar", {
              color: "primary",
              text: "Utilisateur supprimé",
            });
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue, veuillez réessayer dans un moment.",
            });
          });
    },

    resetItemConfirm() {
      this.loading = true;
      ForgotPWService.postSendEmail(this.editedItem.identifiant)
          .then(() => {
            this.loading = false;
            this.$store.dispatch("setSnackbar", {
              color: "succes",
              text: "Email envoyé",
            });
          })
          .catch(() => {
            this.loading = false;
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Une erreur est survenue, veuillez réessayer dans un moment.",
            });
          });
      this.closeReset();
    },

    close() {
      this.dialog = false;
      (this.classes = []),
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeReset() {
      this.dialogReset = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //Valide Formulaire
      this.$refs.form.validate();
      this.loading = true;
      if (this.valid) {
        AdminService.createUser(this.editedItem)
            .then((res) => {
              this.newUserIdentifiant = res.data.identifiant;
              this.initialize();
              if (this.editedItem.role !== "GESTIONNAIRE_ARBS" && this.editedItem.role !== "GESTIONNAIRE_ETABLISSEMENT" && this.editedItem.role !== "GESTIONNAIRE_ETABLISSEMENT_AVANCE") {
                this.dialogRedirection = true;
              }
              this.$store.dispatch("setSnackbar", {
                color: "success",
                text: "Le compte utilisateur a été créé.",
              });
              this.loading = false;
              this.dialog = false;
            })
            .catch((err) => {
              if (err) {
                this.$store.dispatch("setSnackbar", {
                  color: "error",
                  text: "L'utilisateur n'a pas pu être créé, une erreur est survenue.",
                });
                this.loading = false;
                this.dialogRedirection = false;
              }
            });
      } else {
        this.loading = false;
        this.dialogRedirection = false;
      }
    },
    redirectUserConfirm() {
      this.$router.push({
        path: `/admin/users/${this.newUserIdentifiant}/manuels`,
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      }).finally(() => {
        this.newUserIdentifiant = '';
      });
    },
    closeRedirection() {
      this.dialog = false;
      this.dialogRedirection = false;
      this.newUserIdentifiant = '';
    },

    affecterOuvrages() {
      this.$router.push({
        name: "AffectionOuvrages",
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    cleanAccounts() {
      this.$router.push({
        name: "UserClean",
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    exportUtilisateurs() {
      this.$router.push({
        name: "ExportsUtilisateurs",
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },

    desaffecterOuvrages() {
      this.$router.push({
        name: "DesaffectionOuvrages",
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },

    suppressionArchivageCompte() {
      this.$router.push({
        name: "SuppressionArchivageCompte",
      }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.AccessButton {
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0;
}

.pointeur {
  cursor: pointer;
}

</style>
