<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <UsersTab />
  </v-card>
</template>

<script>
import UsersTab from "/src/components/AdminVueComponent/UsersTab.vue";

export default {
  components: {
    UsersTab,
  },
};
</script>